<template>
  <div>
    <CyDataTableYdApi
      id="cy-credentials-table"
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'credentials' }"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :searchable-fields="$static.searchableFields"
      :bulk="hasBulkModeEnabled"
      key-field="canonical">
      <template #table-cmp-header-actions="{ selected }">
        <template v-if="!_.isEmpty(selected)">
          <CyDevBtn
            :wrap-dev-text="false"
            class="my-0 mr-2"
            @click.native="setMissingOwners(selected)">
            Set missing owner
          </CyDevBtn>

          <CyButton
            theme="error"
            icon="delete"
            data-cy="delete-credential-button"
            @click="openDeleteModal(selected)">
            {{ $tc('deleteCredentialBtn', selected.length, { nb: selected.length }) }}
          </CyButton>
        </template>
        <CyButton
          v-else
          v-has-rights-to="'CreateCredential'"
          icon="add"
          data-cy="add-credential-button"
          :to="{ name: 'newCredential' }">
          {{ $t('addCredentialBtn') }}
        </CyButton>

        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :cancel-btn-func="closeDeleteModal"
          :action-btn-text="$tc('deleteCredentialBtn', toDelete.length, { nb: toDelete.length })"
          :loading="deleting"
          small
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length, { item: toDelete[0].name })"/>
          </p>
          <ul
            v-if="toDelete.length > 1"
            class="items-to-delete">
            <li
              v-for="{ canonical, name } of toDelete"
              :key="canonical">
              <b>{{ name }}</b>
            </li>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>
          <span>{{ item.name }}</span>
          <CyTag
            v-if="$static.cycloidCredentials.includes(item.canonical)"
            variant="primary"
            small
            class="ml-2">
            {{ _.toLower($t('untranslated.default')) }}
          </CyTag>
        </td>

        <td>
          <CyTooltip
            :disabled="_.get(item, 'description.length', 0) <= 40"
            bottom
            max-width="200">
            <template #activator="{ on }">
              <span v-on="on">{{ _.truncate(item.description, { length: 40 }) }}</span>
            </template>
            {{ item.description }}
          </CyTooltip>
        </td>

        <td>
          <CyFormsAssignOwner
            v-if="canUpdateOwner(item)"
            :errors="errors"
            :form-content="item"
            :action-btn-func="assignNewOwner"
            sm/>
          <CyButton
            v-else
            :to="{
              name: 'member',
              params: {
                id: item.owner.id,
                backRouteTo: 'credentials',
              },
            }"
            :disabled="!canGoToMember(item)"
            :readonly="!canGoToMember(item)"
            theme="grey"
            variant="tertiary"
            sm
            member-link
            @click.stop>
            <CyMember
              :member="item.owner"
              simple
              sm/>
          </CyButton>
        </td>

        <td :key="item.canonical">
          <v-row
            no-gutters
            class="credential flex-nowrap">
            <v-avatar
              size="30"
              class="mr-2"
              rounded="0">
              <CyIconCredential
                colour-icon
                hide-tooltip
                no-margin
                :type="item.type"/>
            </v-avatar>
            <span>{{ formatType(item.type) }}</span>
          </v-row>
        </td>

        <td>
          <div>
            <CyCopyBtn
              :copy-value="item.path"
              class="mr-2"
              small/>
            {{ item.path }}
          </div>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyCopyBtn from '@/components/copy-btn.vue'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { cycloidDefault } from '@/store/modules/organization'
import { constructBreadcrumb, displayName, getMissingOwnerObject, hasNoOwner } from '@/utils/helpers'

export default {
  name: 'CyPageCredentials',
  components: {
    CyCopyBtn,
    CyDataTableYdApi,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.credentials'), [
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.securitySection'),
      description: {
        text: this.$t('routes.securitySectionDescription'),
      },
    }
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
    deleting: false,
  }),
  computed: {
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
    }),
    ...mapState('organization/credential', {
      errors: (state) => state.errors,
    }),
    $static () {
      return {
        cycloidCredentials: cycloidDefault.credentials,
        headers: [
          {
            text: this.$t('name'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('forms.fieldDescription'),
            value: 'description',
            align: 'left',
          },
          {
            text: this.$t('owner'),
            align: 'left',
            value: 'owner',
            sort: (a, b) => displayName(a).localeCompare(displayName(b)),
          },
          {
            text: this.$t('forms.type'),
            value: 'type',
            align: 'left',
          },
          {
            text: this.$t('forms.fieldPath'),
            value: 'path',
            align: 'left',
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'description',
            label: this.$t('forms.fieldDescription'),
          },
          {
            name: 'type',
            label: this.$t('forms.type'),
          },
          {
            name: 'path',
            label: this.$t('forms.fieldPath'),
          },
          {
            name: 'owner',
            label: this.$t('owner'),
            filterFunction: displayName,
          },
        ],
      }
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('DeleteCredential')
      return canDelete && !_.$isEmpty(this.credentials)
    },
  },
  methods: {
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    ...mapActions('organization/credential', [
      'UPDATE_CREDENTIAL',
    ]),
    getLinkTarget ({ canonical: credentialCanonical } = {}) {
      return {
        name: 'credential',
        params: { credentialCanonical },
      }
    },
    formatType (type) {
      return {
        aws: 'Amazon Web Services',
        azure: 'Azure (resource manager)',
        azure_storage: 'Azure (storage)',
        ssh: 'SSH',
        gcp: 'Google Cloud Platform',
        basic_auth: 'Basic Authentication',
      }[type] || _.capitalize(type)
    },
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    closeDeleteModal () {
      this.$resetData()
      this.$toggle.showDeleteModal(false)
    },
    async onDelete () {
      const { toDelete } = this
      this.$toggle.deleting(true)
      await this.BULK_DELETE({ keyPath: 'credentials', toDelete })
      this.$toggle.deleting(false)
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    async assignNewOwner ({ formContent, owner }) {
      const { data } = await this.$cycloid.ydAPI.getCredential(this.orgCanonical, formContent.canonical) || {}
      const credential = _.merge(data, { owner: owner.username })
      const successMessage = this.$t('alerts.success.credential.reassigned', {
        credentialName: credential.name,
        owner: displayName(owner),
      })
      await this.UPDATE_CREDENTIAL({ credential, successMessage })
      if (_.isEmpty(this.errors)) this.$refs.cyDataTable.retrieveItems()
    },
    setMissingOwners (items) {
      for (const item of items) item.owner = getMissingOwnerObject()
    },
    canGoToMember ({ owner }) {
      return !hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('GetOrgMember', owner?.username)
    },
    canUpdateOwner ({ canonical, owner }) {
      return hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('UpdateCredential', canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.credentials',
        addCredentialBtn: 'Add @:credential',
        areYouSure: 'Are you really sure you want to delete <b>{item}</b>? | Are you really sure you want to delete the following @:credentials?',
        confirmDeleteTitle: '@:forms.btnDelete @:credential | @:forms.btnDelete @:credentials',
        deleteCredentialBtn: '@:forms.btnDelete {nb} @:credential | @:forms.btnDelete {nb} @:credentials',
      },
      es: {
        title: '@:routes.credentials',
        addCredentialBtn: 'Añadir @:credential',
        areYouSure: '¿Está realmente seguro de que desea eliminar <b>{item}</b>? | ¿Está realmente seguro de que desea eliminar las siguientes @:credentials?',
        confirmDeleteTitle: '@:forms.btnDelete @:credential | @:forms.btnDelete @:credentials',
        deleteCredentialBtn: '@:forms.btnDelete {nb} @:credential | @:forms.btnDelete {nb} @:credentials',
      },
      fr: {
        title: '@:routes.credentials',
        addCredentialBtn: `Ajouter @:credential`,
        areYouSure: `Êtes-vous vraiment sûr de vouloir supprimer <b>{item}</b> ? | Êtes-vous vraiment sûr de vouloir supprimer les @:credentials suivantes ?`,
        confirmDeleteTitle: `@:forms.btnDelete une @:credential | @:forms.btnDelete des @:credentials`,
        deleteCredentialBtn: `@:forms.btnDelete {nb} @:credential | @:forms.btnDelete {nb} @:credentials`,
      },
    },
  },
}
</script>
